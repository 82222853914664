import { useEffect, FunctionComponent, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useConfig } from '../hooks';
import { useTimer } from '~components/hooks';
import StreamImg from '../../scss/images/stream.png';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-use';

export const Stream: FunctionComponent = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const getConfig = useConfig();
  const [isPlay, setPlay] = useState(false);
  const timer = useTimer(getConfig?.stream?.started_at, true);

  if (!getConfig?.stream || !timer) {
    return null;
  }

  const onClick = () => setPlay(true);

  const video = (
    <iframe
      className="stream"
      width={275}
      height={155}
      src={`https://player.twitch.tv/?channel=${getConfig?.stream?.broadcaster_user_login}&parent=${location?.host}&video=${getConfig?.stream?.id}`}
      allowFullScreen
    ></iframe>
  );

  return (
    <div className="stream" onClick={onClick}>
      {isPlay ? (
        video
      ) : (
        <>
          <img src={StreamImg} />
          <div className="stream-online">{t('Stream with prizes')}</div>
          <div className="distribution-item-controls-timer">
            <div className="icon material-icons">timer</div>
            <span>
              {timer?.hours} : {timer?.minutes} : {timer?.seconds}
            </span>
          </div>
          <div className="stream-play">
            <div className="icon material-icons">play_circle</div>
          </div>
        </>
      )}
    </div>
  );
};
