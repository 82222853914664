import _ from 'lodash';
import { useConfig, useGetGaTestId, useProfile } from '../hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Timer } from '~components/event';
import { ButtonTimer } from './ButtonTimer';

export const EventBanner = () => {
  const getProfile = useProfile();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const getConfig = useConfig();
  const getEvent = getConfig?.getEvent;
  const [, ga_abc] = useGetGaTestId();

  if (!getEvent) {
    return null;
  }

  const title =
    _.get(getEvent, `props[${language}].h1`) ||
    t('Event_Title', { eventName: getEvent?.props?.h1 });

  return (
    <>
      <img className="img-bg-breezy" src={getEvent.props?.background} />
      <section>
        <div className="breezy-home-content">
          <h1 className="breezy-home-title">
            {!getProfile
              ? t('Open Counter-Strike Global Offensive cases')
              : title}
          </h1>
          {/* {ga_abc === 0 ? (
            <Link
              className="btn-goto-event"
              data-id="breezy-home-btn"
              to={getEvent.getUrl}
            >
              <span
                className="icon material-icons"
                data-icon="read_more"
              ></span>
              <span
                className="text"
                dangerouslySetInnerHTML={{
                  __html: t(
                    getEvent?.props[language]?.homeBtn || 'breezy-home-btn',
                  ),
                }}
              />
            </Link>
          ) : ga_abc === 1 ? (
            <Link
              className="btn-goto-event"
              data-id="breezy-home-btn"
              to={getEvent.getUrl}
            >
              <span
                className="icon material-icons"
                data-icon="read_more"
              ></span>
              <span className="text">
                <ButtonTimer endDate={getEvent.endDate} isOnlyDay />
              </span>
            </Link>
          ) : ( */}
          <div className="btn-goto-event-wrap">
            <Link
              className="btn-goto-event"
              data-id="breezy-home-btn"
              to={getEvent.getUrl}
            >
              <span
                className="icon material-icons"
                data-icon="read_more"
              ></span>
              <span
                className="text"
                dangerouslySetInnerHTML={{
                  __html: t(
                    getEvent?.props[language]?.homeBtn || 'breezy-home-btn',
                  ),
                }}
              />
            </Link>
            <ButtonTimer endDate={getEvent.endDate} isOnlyDayAndTime />
          </div>
          {/* )} */}
          {/* <Link className="breezy-home-btn" to={getEvent.getUrl}>
          <span
            className="breezy-home-btn-text"
            dangerouslySetInnerHTML={{
              __html: t(
                getEvent?.props[language]?.homeBtn || 'breezy-home-btn',
              ),
            }}
          />
          <ButtonTimer endDate={getEvent.endDate} />
        </Link> */}
        </div>
      </section>
    </>
  );
};
