import { memo, FunctionComponent, useCallback } from 'react';
import { Category as CategoryType, CategoryInputWhere } from '~types';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { CaseBlock } from './CaseBlock';
import { useGetCategories } from '../hooks';
import { Loader } from '../../Loader';

interface CategoryProps {
  getCategory: CategoryType;
  isBattlePassCoins?: boolean;
}

export const Category: FunctionComponent<CategoryProps> = memo(
  ({ getCategory, isBattlePassCoins }: CategoryProps) => (
    <article
      className={`case-row ${getCategory.isSmall && 'case-row-small'}`}
      data-rar={getCategory?.color}
      data-id={getCategory.id}
    >
      <div className="case-row-title">
        <div
          className="case-row-title-name"
          style={{ color: getCategory.color }}
        >
          <span className="mask"></span>
          <span className="name">{getCategory.getName}</span>
        </div>
        {getCategory.getDescription && (
          <div className="case-row-title-sec">{getCategory.getDescription}</div>
        )}
      </div>
      <div className="case-item-wrap">
        {_.map(getCategory?.getCases, getCase => (
          <CaseBlock
            getCase={getCase}
            key={getCase?.id}
            isBattlePassCoins={isBattlePassCoins}
          />
        ))}
      </div>
    </article>
  ),
);

Category.displayName = 'Category';

interface ICategoriesView {
  where?: CategoryInputWhere;
  className?: string | undefined;
}

export const CategoriesView: FunctionComponent<ICategoriesView> = memo(
  ({ where, className = 'cases cases-all' }) => {
    const { loading, error, data, fetchMore } = useGetCategories(where);

    const getCategories = data?.getCategories?.getCategories;
    const count = data?.getCategories?.count;
    const currentLength = _.size(getCategories);

    const onLoadMore = useCallback(async () => {
      try {
        await fetchMore({
          variables: {
            input: { offset: currentLength, limit: 2 },
          },
        });
      } catch (error) {
        //
      }
    }, [currentLength, fetchMore]);

    if (loading) {
      return <Loader />;
    } else if (error) {
      return <>GetCategories | Error! {error.message}</>;
    }

    return (
      <section className={className}>
        <InfiniteScroll
          pageStart={0}
          loadMore={onLoadMore}
          hasMore={currentLength < count}
          loader={<Loader key={0} />}
          className="row"
        >
          {getCategories.map(getCategory => (
            <Category key={getCategory.id} getCategory={getCategory} />
          ))}
        </InfiniteScroll>
      </section>
    );
  },
);
