import { memo, FunctionComponent } from 'react';
import { useConfig } from '../hooks';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useTimer } from '~components/hooks';

interface TimerProps {
  getSaleTime: string;
}

const Timer: FunctionComponent<TimerProps> = memo(({ getSaleTime }) => {
  const eventTimer = useTimer(getSaleTime);
  const { t } = useTranslation();

  if (!eventTimer) {
    return null;
  }

  return (
    <div className="main-banner-timer">
      <span className="day">
        <div className="num">{eventTimer?.days}</div>
        <div className="text">{t('d')}.</div>
      </span>
      <span className="hours">{eventTimer?.hours}</span>:
      <span className="minutes">{eventTimer?.minutes}</span>:
      <span className="seconds">{eventTimer?.seconds}</span>
    </div>
  );
});

Timer.displayName = 'Timer';

export const DiscountBanner: FunctionComponent = memo(() => {
  const { t, i18n } = useTranslation();
  const getConfig = useConfig();

  if (
    !getConfig?.getSaleTime ||
    (!getConfig?.discount && !getConfig.isAnonDiscount)
  ) {
    return null;
  }

  return (
    <div className="main-banner">
      <div className="main-banner-text-wrap">
        <div className="main-banner-text">
          {_.result(
            getConfig?.seo.discountName,
            [i18n.language],
            getConfig?.seo?.discountName?.en,
          )}
        </div>
        {getConfig?.discount > 0 && (
          <>
            <div className="main-banner-percent">{getConfig?.discount}%</div>
            <div className="main-banner-text">
              <span>{t('for all')}</span> {t('cases')}
            </div>
          </>
        )}
      </div>
      <div className="main-banner-timer-wrap">
        <div className="title">
          <span>{t('time lieft')}:</span>
        </div>
        <Timer getSaleTime={getConfig?.getSaleTime} />
      </div>
    </div>
  );
});

DiscountBanner.displayName = 'DiscountBanner';
