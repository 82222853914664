import { useConfig, useProfile } from '../hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { Timer } from '~components/battlePass';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ButtonTimer } from './ButtonTimer';

export const BattlePassBanner = () => {
  const getProfile = useProfile();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const getConfig = useConfig();
  const getBattlePass = getConfig?.getBattlePass;

  if (!getBattlePass) {
    return null;
  }

  const title =
    _.get(getBattlePass, `props[${language}].h1`) ||
    t('Event_Title', { eventName: getBattlePass?.props?.h1 });

  return (
    <>
      {_.includes(getBattlePass?.props?.background, 'mp4') ? (
        <video
          width="320"
          height="240"
          preload="auto"
          controls={false}
          autoPlay
          loop
          playsInline
          muted
        >
          <source src={getBattlePass?.props?.background} type="video/mp4" />
          <source src={getBattlePass?.props?.background} type="video/ogg" />
        </video>
      ) : (
        <LazyLoadImage src={getBattlePass?.props?.background} />
      )}
      <section>
        <div className="breezy-home-content">
          <h1 className="breezy-home-title">
            {!getProfile ? t('best csgo cases') : title}
          </h1>
          <Link className="breezy-home-btn" to={getBattlePass.getUrl}>
            <span
              className="breezy-home-btn-text"
              dangerouslySetInnerHTML={{
                __html: t(
                  getBattlePass?.props[language]?.homeBtn || 'breezy-home-btn',
                ),
              }}
            />
            <ButtonTimer endDate={getBattlePass.endDate} />
          </Link>
        </div>
      </section>
    </>
  );
};
